<template>
    <section class="zc-reservas-main fadeIn">
        <div class="row mb-4">
            <div class="col-auto">
                <p class="f-20 f-500">Reservas pendientes</p>
            </div>
            <div class="col-auto ml-auto">
                <div v-show="$route.name == 'zonas.reservas.calendario'" class="row justify-content-lg-end d-middle-center">
                    <p class="f-12 mr-2">Zona común</p>
                    <el-select v-model="idZona" placeholder="Seleccionar" size="small">
                        <el-option
                        v-for="item in zonas"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-auto">
                <div class="button-like-router justify-content-around d-flex">
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                        <div slot="content" class="text-86">
                            Ver en lista
                        </div>
                        <router-link :to="{ name: 'zonas.reservas.listado' }" class="d-flex d-middle-center">
                            <i class="icon-hamburguer cr-pointer" />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                        <div slot="content" class="text-86">
                            Ver en calendario
                        </div>
                        <router-link :to="{ name: 'zonas.reservas.calendario' }" class="d-flex d-middle-center">
                            <i class="icon-calendar cr-pointer" />
                        </router-link>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="row">
            <router-view :id-zona="idZona" />
        </div>
    </section>
</template>
<script>
import Zonas from '~/services/zonas'
export default {
    data(){
        return {
            searchInput: "",
            mostrarRequierenPago: false,
            idZona: null,
            zonas: [],
        };
    },
    computed: {
        vistaListado(){
            return this.$route.name === "zonas.reservas.listado";
        },
    },
    mounted(){
        this.getZonasComunes()
    },
    methods: {
        verListado(){
            this.$router.push({ name: "zonas.reservas.listado" });
        },
        verCalendario(){
            this.$router.push({ name: "zonas.reservas.calendario" });
        },
        setSearch(){
            this.$store.dispatch("reservas/search", this.searchInput);
        },
        async getZonasComunes(){
            try {
                const { data } = await Zonas.listarZonasComunes();
                this.zonas = data.data
            } catch (error){
                this.errorCatch(error)
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.zc-reservas-main {
  .button-like-router {
    height: 32px;
    width: 64px;
    border: 1px solid #cfd7df;
    border-radius: 4px;
    a {
      height: 32px;
      width: 32px;
      border-radius: 4px;
      color: #000;
      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
      &.router-link-exact-active.router-link-active {
        background: var(--color-general);
        color: #fff;
      }
    }
  }
}
</style>
